`<template>
  <div class="container">
    <div class="card card-custom example example-compact">
      <form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <p class="font-weight-bold offset-1 text-uppercase">
            {{ $t("COMMON.general") }}
          </p>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("COMPANY.TITLE") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.title.$model"
                :state="validateState('title')"
                aria-describedby="input-1-live-feedback"
              />
              <div class="validation error" v-if="$v.form.title.$error">
                {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("FORM.EMAIL") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="email-help"
              />
              <div class="validation error" v-if="$v.form.email.$error">
                {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">
              {{ $t("FORM.PHONE") }}
            </label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder="5XXXXXXXXX"
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
              />
              <template v-if="$v.form.phone.$error">
                <div class="validation error" v-if="!$v.form.phone.required">
                  {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
                </div>
                <div class="validation error" v-if="!$v.form.phone.numeric">
                  {{ $t("VALIDATION.NUMERIC") }}
                </div>
                <div class="validation error" v-if="!$v.form.phone.maxLength">
                  {{
                    $t("VALIDATION.MAX_LENGTH", {
                      num: $v.form.phone.$params.maxLength.max
                    })
                  }}
                </div>
              </template>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">{{
              $t("COMPANY.TAX_NUMBER")
            }}</label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.taxNumber.$model"
                :state="validateState('taxNumber')"
                aria-describedby="input-1-live-feedback"
              />
              <div class="validation error" v-if="$v.form.taxNumber.$error">
                {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">{{
              $t("COMPANY.TAX_ADMINISTRATION")
            }}</label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.taxAdministration.$model"
                :state="validateState('taxAdministration')"
                aria-describedby="input-1-live-feedback"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">{{
              $t("COMPANY.TAX_CITY")
            }}</label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="$v.form.taxCity.$model"
                :state="validateState('taxCity')"
                aria-describedby="input-1-live-feedback"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">{{
              $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_RATE")
            }}</label>
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder=""
                id="kt_money_input"
                v-model.number="$v.form.commission.$model"
                :state="validateState('commission')"
                aria-describedby="input-1-live-feedback"
              />
              <template v-if="$v.form.commission.$error">
                <div
                  class="validation error"
                  v-if="!$v.form.commission.required"
                >
                  {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
                </div>
                <div
                  class="validation error"
                  v-if="!$v.form.commission.numeric"
                >
                  {{ $t("VALIDATION.ONLY_INTEGERS") }}
                </div>
                <div
                  class="validation error"
                  v-if="!$v.form.commission.minValue"
                >
                  {{
                    $t("VALIDATION.MIN_VALUE", {
                      name: $t(
                        "PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_RATE"
                      ),
                      min: $v.form.commission.$params.minValue.min
                    })
                  }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-9 ml-lg-auto d-flex justify-content-end">
              <button
                type="submit"
                class="btn btn-success mr-5"
                :disabled="isSubmitting"
              >
                <span
                  v-if="isSubmitting"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ $t("FORM.UPDATE") }}
              </button>
            </div>
          </div>
        </div>
        <div class="card-footer" v-if="ordersTotal.length > 0">
          <h6 class="col-12">
            {{
              $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMPLETED_ORDERS_AMOUNT")
            }}
          </h6>
          <div
            v-for="(orderTotalItem, idx) in ordersTotal"
            :key="`orderTotal-${idx}-${orderTotalItem.id}`"
            class="d-flex flex-row justify-content-between"
          >
            <span class="col-4">{{ orderTotalItem.name }}</span>
            <span class="col-4">{{ orderTotalItem.orderTotal }}</span>
            <span class="col-4"
              >({{
                $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDERS_COUNT", {
                  count: orderTotalItem.ordersCount
                })
              }})</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  required,
  numeric,
  minValue,
  maxLength
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  props: ["isSubmitting", "selectedBranch"],
  computed: {
    branch() {
      return this.selectedBranch;
    },
    branchId() {
      return this.selectedBranch.id;
    }
  },
  data() {
    return {
      form: {
        title: null,
        email: null,
        phone: null,
        taxNumber: "",
        taxAdministration: "",
        taxCity: "",
        hasQuickSale: false,
        commission: 0,
        representative: null
      },
      ordersTotal: []
    };
  },
  validations: {
    form: {
      title: { required },
      email: { required },
      phone: {
        numeric,
        required,
        maxLength: maxLength(10)
      },
      taxNumber: {},
      taxAdministration: {},
      taxCity: {},
      commission: {
        numeric,
        minValue: minValue(0),
        required
      },
      hasQuickSale: {},
      representative: {}
    }
  },
  async mounted() {
    this.setForm();
    this.fetchOrdersTotal();
  },
  methods: {
    async setForm() {
      const branch = this.branch;
      this.form.title = branch?.title || null;
      this.form.email = branch?.email || null;
      this.form.phone = branch?.phone || null;
      this.form.taxNumber = branch?.taxNumber || "";
      this.form.taxAdministration = branch?.taxAdministration || "";
      this.form.taxCity = branch?.taxCity || "";
      this.form.hasQuickSale = branch?.hasQuickSale || false;
      this.form.commission = branch?.commission || 0;
    },
    validateState(title) {
      const { $dirty, $error } = this.$v.form[title];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit("submit", this.form);
    },
    async fetchOrdersTotal() {
      try {
        const ordersTotals = await this.$store.dispatch(
          "orders/GET_ORDERS_TOTAL",
          this.branchId
        );
        this.ordersTotal = ordersTotals;
      } catch (error) {
        console.log("fetchOrdersTotal error: ", error);
      }
    }
  }
};
</script>
