<template>
  <div>
    <FormLoader v-if="isLoading" />
    <EditBranchForm
      v-else
      v-on:submit="onSubmit"
      :isSubmitting="isSubmitting"
      :selectedBranch="selectedBranch"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapActions } from "vuex";
import Api from "@/services/api";
import EditBranchForm from "@/components/forms/EditBranchForm.vue";
import FormLoader from "@/components/forms/FormLoader.vue";

export default {
  name: "EditBranch",
  components: { EditBranchForm, FormLoader },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      selectedBranch: null
    };
  },
  computed: {
    branchId() {
      return this.$route.params.branchId;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.CORPORATE") },
      { title: this.$t("ROUTES.BRANCHES") },
      { title: this.$t("MENU.EDIT") }
    ]);
  },
  beforeRouteLeave(to, from, next) {
    this.clearSelectedCompany();
    next();
  },
  created() {
    this.fetchCompany();
  },
  methods: {
    ...mapActions({
      clearSelectedCompany: "company/CLEAR_SELECTED_COMPANY",
      toggleCompanyActiveStatus: "company/TOGGLE_COMPANY_ACTIVE_STATUS"
    }),
    async fetchCompany() {
      this.isLoading = true;
      try {
        const res = await this.$store.dispatch(
          "company/GET_COMPANY",
          this.branchId
        );
        const branch = res.data;
        this.selectedBranch = branch;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$bvToast.toast(this.$t("FORM.ERROR"), {
          title: this.$t("FORM.ERROR_TITLE"),
          variant: "danger",
          autoHideDelay: 5000
        });
        this.$router.replace({ name: "company-companies" });
      }
    },
    onSubmit(company) {
      const successTitle = this.$t("FORM.SUCCESS_TITLE");
      const errorTitle = this.$t("FORM.ERROR_TITLE");
      const successMessage =
        this.$t("COMPANY.COMPANY") + this.$t("FORM.UPDATED");
      const errorMessage = this.$t("FORM.ERROR");
      this.isSubmitting = true;
      Api.put(`companies/${this.branchId}`, company)
        .then(() => {
          this.$root.$bvToast.toast(successMessage, {
            title: successTitle,
            variant: "success",
            autoHideDelay: 5000
          });
          this.$router.replace({ name: "company-companies" });
        })
        .catch(() => {
          this.$root.$bvToast.toast(errorMessage, {
            title: errorTitle,
            variant: "danger",
            autoHideDelay: 5000
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
